<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      data: [],
      series: [],
      chartOptions: {}
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const request = new Service();
      request.get(`statistics/district/${this.city}`).then(res => {
        let series = [
          {
            name: "Confirmado",
            data: []
          },
          { name: "Suspeitos", data: [] },
          { name: "Descartados", data: [] },
          { name: "Curados", data: [] },
          {
            name: "Óbitos",
            data: []
          }
        ];
        let categories = [];
        res.forEach(element => {
          categories.push(element.district);

          let confirmed =
            Number(element.c_qty_isolated) +
            Number(element.c_qty_interned) +
            Number(element.c_qty_lethal) +
            Number(element.c_qty_recovered);

          series[0].data.push(confirmed);
          series[1].data.push(
            Number(element.s_qty_isolated) + Number(element.s_qty_interned)
          );
          series[2].data.push(Number(element.qty_discarded));
          series[3].data.push(element.c_qty_recovered);
          series[4].data.push(Number(element.c_qty_lethal));
        });

        this.series = series;

        let chartOptions = {
          chart: {
            type: "bar",
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: true,
                zoomin: false,
                zoomout: false,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              }
            },
            zoom: {
              enabled: true
            }
          },
          colors: ["#EA3546", "#F86624", "#999", "#08BB68", "#000"],
          title: {
            text: "Distribuição de casos por bairros e distritos",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              color: "#263238"
            }
          },

          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          legend: {
            show: true,
            position: "top"
          },
          xaxis: {
            categories: categories
          }
        };
        this.chartOptions = chartOptions;
      });
    }
  }
};
</script>
