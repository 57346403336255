<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      data: [],
      series: [],
      chartOptions: {}
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const request = new Service();
      request.get(`statistics/pregnant/${this.city}`).then(res => {
        let series = [
          {
            name: "Confirmado",
            data: []
          },
          { name: "Suspeitos", data: [] },
          // {
          //   name: "Descartados",
          //   data: [],
          // },
          {
            name: "Curados",
            data: []
          },
          {
            name: "Óbitos",
            data: []
          }
        ];
        let categories = [];
        res.forEach(element => {
          categories.push(
            element.pregnant == "1" ? "Gestante" : "Não gestante"
          );
          let qtc = parseInt(element.qty_confirmed);
          let qtr = parseInt(element.qty_recovered);
          let qtl = parseInt(element.qty_lethal);

          let d = qtc + qtr + qtl;

          series[0].data.push(d);
          series[1].data.push(element.qty_suspicious);
          // series[2].data.push(element.qty_discarded);
          series[2].data.push(element.qty_recovered);
          series[3].data.push(element.qty_lethal);
        });

        this.series = series;

        let chartOptions = {
          chart: {
            type: "bar",
            stacked: true,
            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: false,
                zoom: true,
                zoomin: false,
                zoomout: false,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              }
            },
            zoom: {
              enabled: true
            }
          },

          colors: ["#EA3546", "#F86624", "#08BB68", "#000"],
          title: {
            text: "Quantitativo de gestantes por caso",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              color: "#263238"
            }
          },

          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          legend: {
            show: true,
            position: "top"
          },
          xaxis: {
            categories: categories
          }
        };
        this.chartOptions = chartOptions;
      });
    }
  }
};
</script>
