<template>
  <section style="padding-top: 20px;">
    <div v-if="flag == true" class="center">
      <h5>Carregando dados...</h5>
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>
    <div v-else class="mr-2">
      <b-modal
        header-bg-variant="outline-success"
        style="border-radius: 10px;"
        size="lg"
        id="modal-stores"
        hide-footer
        scrollable
        title="ESTABELECIMENTOS"
      >
        <modal-stores :city_id="$route.params.id"></modal-stores>
      </b-modal>

      <div class="col-md-12 d-flex justify-content-center">
        <div class="col-md-3">
          <select
            class="selectCity form-control custom-select"
            id="validationCustom07"
            v-model="currentCity"
          >
            <option
              v-for="(item, index) in cities"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="d-flex mt-3 mb-3 justify-content-center">
        <a
          style="color:white"
          target="_blank"
          @click="updateTime(500)"
          :href="`http://covid19.ifce.edu.br/api/relatory/${$route.params.id}`"
          ><b-button
            size="big"
            variant="primary"
            class="pt-3 pb-3"
            :disabled="!relatoryTime.cache_exists"
            ><b-icon-download class="mr-2" /><strong>VISUALIZAR BOLETIM</strong
            ><br />
            <small
              style="color: #ddd;font-size:11px"
              v-if="relatoryTime.cache_exists"
              >Atualizado em {{ relatoryTime.cache_date }}</small
            >
            <small style="color: #ddd;font-size:11px" v-else>{{
              relatoryTime.cache_date
            }}</small>
          </b-button></a
        >
      </div>
      <b-row>
        <div
          class="col-md-2 col-sm-12"
          v-for="(item, index) in data"
          :key="index"
        >
          <b-card bg-variant="light">
            <div class="d-flex flex-column align-items-center">
              <h4 style="text-align:center;">
                <b style="color:#000">{{ item.value }}</b>
              </h4>
              <h6 style="text-align:center;">{{ item.label }}</h6>
              <img
                height="20px"
                :src="require(`../assets/img/${item.img}.svg`)"
              />
            </div>
          </b-card>
        </div>
      </b-row>

      <hr />
      <div class="d-flex mt-1 justify-content-center">
        <div class="col-md-3">
          <select v-model="districtId" class="custom-select form-control">
            <option :value="null">Todos</option>
            <option
              v-for="(item, index) in districts"
              :key="index"
              :value="item.id"
            >
              {{ item.name }} ({{ item.incidents }})
            </option>
          </select>
        </div>
      </div>
      <b-row>
        <div class="col-md-12">
          <b-card class="" bg-variant="light">
            <div><b>Evolução diária dos casos</b></div>
            <incidents-chart-day
              :districtId="districtId"
              :city="Number(this.$route.params.id)"
            />
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-test-type
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-clinic
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-reporting-unity
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
      </b-row>
      <b-row>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-isolated :city="Number(this.$route.params.id)" />
          </b-card>
        </div>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-test-type-total
              :city="Number(this.$route.params.id)"
            />
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="mt-1 col-md-12 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-district :city="Number(this.$route.params.id)" />
          </b-card>
        </div>
      </b-row>

      <div class="d-flex mt-1 justify-content-center">
        <div class="col-md-3">
          <select v-model="state" class="custom-select form-control">
            <option
              v-for="(item, index) in type"
              :key="index"
              :value="item.label"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <b-row>
        <div class="mt-1 col-md-4 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-pregnant :city="Number(this.$route.params.id)" />
          </b-card>
        </div>
        <div class="mt-1 col-md-4 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-gender
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
        <div class="mt-1 col-md-4 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-age
              :city="Number(this.$route.params.id)"
              :currentFilter="state"
            />
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-symptom :city="Number(this.$route.params.id)" />
          </b-card>
        </div>
        <div class="mt-1 col-md-6 col-sm-12 col-12">
          <b-card class="" bg-variant="light">
            <incidents-chart-morbities :city="Number(this.$route.params.id)" />
          </b-card>
        </div>
      </b-row>
    </div>
  </section>
</template>
<script>
import Service from "../services";
import IncidentsChart from "../components/Charts/IncidentsChart";
import IncidentsChartAge from "../components/Charts/IncidentsChartAge";
import IncidentsChartDay from "../components/Charts/IncidentsChartDay2";
import IncidentsChartGender from "../components/Charts/IncidentsChartGender";
import IncidentsChartDistrict from "../components/Charts/IncidentsChartDistrict";
import IncidentsChartIsolated from "../components/Charts/IncidentsChartIsolated";
import IncidentsChartPregnant from "../components/Charts/IncidentsChartPregnant";
import IncidentsChartClinic from "../components/Charts/IncidentsChartClinic";
import IncidentsChartSymptom from "../components/Charts/IncidentsChartSymptom";
import IncidentsChartMorbities from "../components/Charts/IncidentsChartMorbities";
import incidentsTestType from "../components/Charts/incidentsTestType";
import incidentsTestTypeTotal from "../components/Charts/incidentsTestTypeTotal";
import incidentsChartReportingUnity from "../components/Charts/incidentsChartReportingUnity";

import ModalStores from "../components/Modal/ModalStores";
export default {
  props: {},
  components: {
    "incidents-chart-reporting-unity": incidentsChartReportingUnity,
    "incidents-chart-test-type": incidentsTestType,
    "incidents-chart-test-type-total": incidentsTestTypeTotal,
    "incidents-chart": IncidentsChart,
    "incidents-chart-age": IncidentsChartAge,
    "incidents-chart-day": IncidentsChartDay,
    "incidents-chart-district": IncidentsChartDistrict,
    "incidents-chart-isolated": IncidentsChartIsolated,
    "incidents-chart-gender": IncidentsChartGender,
    "incidents-chart-pregnant": IncidentsChartPregnant,
    "incidents-chart-clinic": IncidentsChartClinic,
    "incidents-chart-symptom": IncidentsChartSymptom,
    "incidents-chart-morbities": IncidentsChartMorbities,
    ModalStores
  },
  data() {
    return {
      flag: true,
      relatoryTime: {},
      data: [],
      cities: [],
      districts: [],
      districtId: null,
      currentCity: this.$route.params.id,
      state: "confirmed",
      city: "",
      type: [
        {
          name: "Todos",
          label: "all"
        },
        {
          name: "Confirmados",
          label: "confirmed"
        },
        {
          name: "Suspeitos",
          label: "suspicious"
        },
        {
          name: "Curados",
          label: "recovered"
        },
        {
          name: "Descartados",
          label: "discarded"
        },
        {
          name: "Óbitos",
          label: "lethal"
        }
      ]
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const request = new Service();
      this.updateTime();
      request.get(`statistics/district/${this.$route.params.id}`).then(res => {
        this.districts = res.map(item => {
          return {
            id: item.id,
            name: item.district,
            incidents:
              Number(item.c_qty_isolated) +
              Number(item.c_qty_interned) +
              Number(item.c_qty_lethal) +
              Number(item.c_qty_recovered) +
              Number(item.s_qty_isolated) +
              Number(item.s_qty_interned)
          };
        });
      });
      // http://covid19.ifce.edu.br/api/relatory/time/22
      request.get(`statistics/cities/${this.$route.params.id}`).then(res => {
        this.city = res.name;
        let aux = [
          {
            label: "Confirmados",
            img: "line-red",
            value:
              res.c_qty_isolated +
              res.c_qty_interned +
              res.c_qty_lethal +
              res.c_qty_recovered
          },
          {
            label: "Suspeitos",
            img: "line-yellow",
            value: res.s_qty_isolated + res.s_qty_interned
          },
          {
            label: "Descartados",
            img: "line-grey",
            value: res.qty_discarded
          },
          {
            label: "Curados",
            img: "line-green",
            value: res.c_qty_recovered
          },
          {
            label: "Óbitos",
            img: "line-black",
            value: res.c_qty_lethal
          },
          {
            label: "Letalidade",
            img: "line-red",
            value:
              (
                (res.c_qty_lethal /
                  (res.c_qty_isolated +
                    res.c_qty_recovered +
                    res.c_qty_interned +
                    res.c_qty_lethal)) *
                  100 || 0
              ).toFixed(2) + "%"
          }
        ];
        this.data = aux;
        this.flag = false;
      });
      request.get("city", { size: 184 }).then(res => {
        this.cities = res.data;
      });
    },
    updateTime(timeout = 0) {
      setTimeout(() => {
        const request = new Service();
        request.get(`relatory/time/${this.$route.params.id}`).then(res => {
          this.relatoryTime = res;
        });
      }, timeout);
    }
  },
  watch: {
    currentCity() {
      this.$router.push(`/cidade/${this.currentCity}`);
      document.location.reload(true);

      // this.$router.push(`cidade/${this.currentCity}`);
    }
  }
};
</script>
<style scoped>
.center {
  flex-direction: column;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; /** ISSO AQUI ALINHA VERTICALMENTE */
}
.cardType {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
  margin: 0 5px 0 5px;
  border-radius: 5px;
  padding: 10px;
}
.cardTypeChartRight {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
  margin: 0 20px 0 0px;
  border-radius: 5px;
  padding: 10px;
}
.cardTypeChartLeft {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
  margin: 0 0 0 20px;
  border-radius: 5px;
  padding: 10px;
}
.select-custom-city {
  border: 0.5px solid #000000aa;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 0 20px;
  font-size: 2.3rem;
  align-self: center;
  color: #000000aa;
}
.selectCity {
  font-size: 1.6rem;
  text-align-last: center;
}
</style>
