<script>
import { Line } from "vue-chartjs";
import Service from "../../services";
export default {
  extends: Line,
  props: {
    city: {
      type: Number,
      default: 0
    },
    districtId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      chartdata: {
        labels: [],
        datasets: [],
        borderWidth: 2,
        fill: false
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Dias"
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Qtd. de casos"
              }
            }
          ]
        }
      }
    };
  },
  async mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      const request = new Service();
      const res = await request.get(`statistics/day/${this.city}`, {
        by_district: this.districtId ? this.districtId : null
      });
      let labels = [];
      let data = [
        {
          label: "Confirmado",
          data: [],
          borderColor: "#EA3546",
          backgroundColor: "#EA3546",
          fill: false
        },
        {
          label: "Suspeitos",
          data: [],
          borderColor: "#F86624",
          backgroundColor: "#F86624",
          fill: false
        },
        {
          label: "Curados",
          data: [],
          borderColor: "#08BB68",
          backgroundColor: "#08BB68",
          fill: false
        },
        {
          label: "Óbitos",
          data: [],
          borderColor: "#000",
          backgroundColor: "#000",
          fill: false
        },
        {
          label: "Testes realizados",
          data: [],
          borderColor: "#85D3FF",
          backgroundColor: "#85D3FF",
          fill: false,
          hidden: true
        },
        {
          label: "Novos confirmados por dia",
          data: [],
          borderColor: "#FFC107",
          backgroundColor: "#FFC107",
          fill: false,

          hidden: true
        }
      ];

      res.forEach(e => {
        let [, m, d] = e.day.split("-", 3);
        labels.push(`${d}/${m}`);

        let qtd_c = Number(e.qty_confirmed);
        let qtd_r = Number(e.qty_recovered);
        let qtd_l = Number(e.qty_lethal);

        data[0].data.push(qtd_c + qtd_r + qtd_l);
        data[1].data.push(e.qty_suspicious);
        data[2].data.push(e.qty_recovered);
        data[3].data.push(e.qty_lethal);
        data[4].data.push(e.qty_tests);
        data[5].data.push(e.qty_incidents);
      });
      this.chartdata.labels = labels;
      this.chartdata.datasets = data;
      this.renderChart(this.chartdata, this.options);
    }
  },
  watch: {
    districtId() {
      this.populate();
    }
  }
};
</script>

<style></style>
