<template>
  <section>
    <div style="min-height: 120px;">
      <b-form-group>
        <b-input
          debounce="100"
          v-model="search"
          type="text"
          placeholder="Buscar por produto ou estabelecimento..."
        />
      </b-form-group>
      <span class="small pl-1"
        >Mostrando {{ filteredItems.length }} loja{{
          filteredItems.length == 1 ? "" : "s"
        }}.</span
      >
      <hr />
      <b-card class="mb-1" v-for="item in filteredItems" v-bind:key="item.id">
        <h4>{{ item.name }}</h4>
        <b-card-text>
          <b-list-group>
            <b-list-group-item
              v-for="product in item.products"
              :key="product.id"
              >{{ product.quantity }} unidade{{
                product.quantity == 1 ? "" : "s"
              }}
              de {{ product.name }}</b-list-group-item
            >
          </b-list-group>
        </b-card-text>
      </b-card>
    </div>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  props: {
    city_id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      stores: [],
      search: ""
    };
  },
  methods: {
    populate() {
      const service = new Service();
      service.get(`store/city/${this.city_id}`).then(res => {
        this.stores = res;
      });
    }
  },
  computed: {
    filteredItems() {
      if (this.search.length == 0) return this.stores;
      return this.stores.filter(
        s =>
          s.name.includes(this.search) ||
          s.products.filter(p => p.name.includes(this.search)).length
      );
    }
  },
  mounted() {
    this.populate();
  },
  watch: {}
};
</script>
