<template>
  <div>
    <apexchart
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    currentFilter: {
      type: String
    },
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      series: [0, 0, 0],
      data: [],
      chartOptions: {
        title: {
          text: "Distribuição de casos confirmados por tipo de testes",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        legend: {
          show: true,
          position: "top"
        },
        chart: {
          type: "donut",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            }
          }
        },
        labels: ["Teste rápido - antígeno", "Teste rápido - anticorpo"],
        theme: {
          palette: "palette6"
        },
        responsive: [
          {
            options: {
              chart: {
                padding: 10
              }
            }
          }
        ]
      }
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const request = new Service();
      request.get(`statistics/test_type/${this.city}`).then(res => {
        this.data = res;
        let confirmed = res["confirmed"];
        let lethal = res["lethal"];
        let recovered = res["recovered"];
        let qty_fast_test_at = 0;
        let qty_fast_test_ac = 0;
        let qty_rt_pcr = 0;

        qty_fast_test_at += confirmed
          ? parseInt(confirmed.qty_fast_test_at)
          : 0;
        qty_fast_test_at += lethal ? parseInt(lethal.qty_fast_test_at) : 0;
        qty_fast_test_at += recovered
          ? parseInt(recovered.qty_fast_test_at)
          : 0;

        qty_fast_test_ac += confirmed
          ? parseInt(confirmed.qty_fast_test_ac)
          : 0;
        qty_fast_test_ac += lethal ? parseInt(lethal.qty_fast_test_ac) : 0;
        qty_fast_test_ac += recovered
          ? parseInt(recovered.qty_fast_test_ac)
          : 0;

        qty_rt_pcr += confirmed ? parseInt(confirmed.qty_rt_pcr) : 0;
        qty_rt_pcr += lethal ? parseInt(lethal.qty_rt_pcr) : 0;
        qty_rt_pcr += recovered ? parseInt(recovered.qty_rt_pcr) : 0;

        this.series = [qty_fast_test_at, qty_fast_test_ac, qty_rt_pcr];
      });
    }
  }
};
</script>
