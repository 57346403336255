<template>
  <div>
    <apexchart
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      data: [],
      series: [0, 0],
      chartOptions: {
        title: {
          text: "Distribuição de casos confirmados por unidades de saúde",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        chart: {
          type: "pie",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            }
          }
        },
        labels: [
          "Atenção Primária",
          "Hospital Público",
          "Hospital Particular",
          "Unidade de Pronto Atendimento (UPA)",
          "Laboratório Privado"
        ],
        theme: {
          palette: "palette6"
        },

        legend: {
          show: true,
          position: "top"
        }
      }
    };
  },
  created() {
    const request = new Service();
    request.get(`statistics/reporting_unity/${this.city}`).then(res => {
      let confirmed = res["confirmed"];
      let lethal = res["lethal"];
      let recovered = res["recovered"];
      let qty_ru_1 = 0;
      let qty_ru_2 = 0;
      let qty_ru_3 = 0;
      let qty_ru_4 = 0;
      let qty_ru_5 = 0;
      qty_ru_1 += confirmed ? parseInt(confirmed.qty_ru_1) : 0;
      qty_ru_1 += lethal ? parseInt(lethal.qty_ru_1) : 0;
      qty_ru_1 += recovered ? parseInt(recovered.qty_ru_1) : 0;

      qty_ru_2 += confirmed ? parseInt(confirmed.qty_ru_2) : 0;
      qty_ru_2 += lethal ? parseInt(lethal.qty_ru_2) : 0;
      qty_ru_2 += recovered ? parseInt(recovered.qty_ru_2) : 0;

      qty_ru_3 += confirmed ? parseInt(confirmed.qty_ru_3) : 0;
      qty_ru_3 += lethal ? parseInt(lethal.qty_ru_3) : 0;
      qty_ru_3 += recovered ? parseInt(recovered.qty_ru_3) : 0;

      qty_ru_4 += confirmed ? parseInt(confirmed.qty_ru_4) : 0;
      qty_ru_4 += lethal ? parseInt(lethal.qty_ru_4) : 0;
      qty_ru_4 += recovered ? parseInt(recovered.qty_ru_4) : 0;

      qty_ru_5 += confirmed ? parseInt(confirmed.qty_ru_5) : 0;
      qty_ru_5 += lethal ? parseInt(lethal.qty_ru_5) : 0;
      qty_ru_5 += recovered ? parseInt(recovered.qty_ru_5) : 0;

      this.series = [qty_ru_1, qty_ru_2, qty_ru_3, qty_ru_4, qty_ru_5];
    });
  }
};
</script>
