<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    city: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {}
    };
  },
  created() {
    const request = new Service();
    let series = [];
    let categories = [
      "Confirmados",
      // "Descartados",
      "Óbitos",
      "Curados",
      "Suspeitos"
    ];
    request.get(`statistics/symptom/${this.city}`).then(res => {
      res.forEach(e => {
        series.push({
          name: e.symptom != null ? e.symptom : "Assintomático",
          data: [
            Number(
              parseInt(e.qty_confirmed) +
                parseInt(e.qty_lethal) +
                parseInt(e.qty_recovered)
            ),
            // Number(e.qty_discarded),
            Number(e.qty_lethal),
            Number(e.qty_recovered),
            Number(e.qty_suspicious)
          ]
        });
        this.series = series;
      });
    });

    this.chartOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: true,
            zoomin: false,
            zoomout: false,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories
      },
      title: {
        text: "Distribuição dos sintomas por casos",
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#263238"
        }
      },
      theme: {
        palette: "palette6"
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        position: "top"
      }
    };
    this.series = series;
  }
};
</script>
